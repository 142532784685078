import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

/* Layout */
import Layout from "@/layout/index.vue";

/* Router Modules */
// import componentsRouter from './modules/components'
// import chartsRouter from './modules/charts'
// import tableRouter from './modules/table'
// import nestedRouter from './modules/nested'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    meta: {
      elIcon: "el-icon-s-home",
    },
    children: [
      {
        path: "home",
        component: () => import("@/views/Home.vue"),
        name: "Home",
        meta: { title: "Home", elIcon: "el-icon-s-home", affix: true },
      },
    ],
  },
  {
    path: '/activity',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: 'ActivityList',
      elIcon: 'el-icon-star-off'
    },
    children: [
      {
        path: 'list',
        component: () => import('@/views/activity/ActivityList.vue'),
        name: 'ActivityList',
        meta: {
          elIcon: 'el-icon-menu',
          title: 'ActivityList',
          roles: ['admin', 'user'], // you can set roles in root nav
        }
      }],
  },
  {
    path: '/user_activity',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: 'UserActivity',
      elIcon: 'el-icon-news'
    },
    children: [
      {
        path: 'list',
        component: () => import('@/views/activity/UserActivity.vue'),
        name: 'UserActivity',
        meta: {
          elIcon: 'el-icon-menu',
          title: 'UserActivity',
          roles: ['admin', 'user'],
        }
      },
    ],
  },
  {
    path: '/map',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: 'Map',
      elIcon: 'el-icon-map-location'
    },
    children: [
      {
        path: 'list',
        component: () => import('@/views/activity/map/MapList.vue'),
        name: 'MapList',
        meta: {
          elIcon: 'el-icon-menu',
          title: 'MapList',
          roles: ['admin', 'user'],
        }
      },
      ],
  },
  {
    path: '/text',
    component: Layout,
    meta: {
        title: 'Text',
        elIcon: 'el-icon-question'
     },
    children: [
      {
        path: 'list',
        component: () => import('@/views/text/list.vue'),
        name: 'TextList',
        meta: { title: 'TextList',elIcon: 'el-icon-menu' }
      },
      {
        path: 'add',
        component: () => import('@/views/text/add.vue'),
        name: 'TextAdd',
        meta: { title: 'TextAdd' ,elIcon: 'el-icon-menu'}
      },
	  {
        path: 'edit',
        component: () => import('@/views/text/add.vue'),
        name: 'TextEdit',
        meta: { title: 'TextEdit' ,elIcon: 'el-icon-menu',},
        hidden: true
      },
      {
        path: 'category',
        component: () => import('@/views/text/category.vue'),
        name: 'TextCategory',
        meta: {title: 'TextCategory', elIcon: 'el-icon-menu'}
      }
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/Login.vue"),
    hidden: true,
  },
  {
    path: '/file-manager/images.html',
    name: `images`,
    hidden: true,
    meta: {
      title: '上传图片',
      elIcon: 'el-icon-menu'
    },
    component: () => import('@/components/UploadFile/UEditorImages.vue')
  },
  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/redirect/:path*",
        component: () => import("@/views/redirect/index.vue"),
      },
    ],
  },
  {
    path: "/404",
    component: () => import("@/views/error-page/404.vue"),
    hidden: true,
  },
  {
    path: "/401",
    component: () => import("@/views/error-page/401.vue"),
    hidden: true,
  },

];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
  {
    path: '/submit',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: 'Submit',
      elIcon: 'el-icon-tickets'
    },
    children: [
      {
        path: 'feedback',
        component: () => import('@/views/submit/Feedback.vue'),
        name: 'Feedback',
        meta: {
          elIcon: 'el-icon-menu',
          title: 'Feedback',
          roles: ['admin', 'user'], // you can set roles in root nav
        }
      },
      {
        path: 'comment',
        component: () => import('@/views/submit/Comment.vue'),
        name: 'Comment',
        meta: {
          elIcon: 'el-icon-menu',
          title: 'Comment',
          roles: ['admin', 'user'], // you can set roles in root nav
        }
      }
      ],
  },
  {
    path: '/user-step',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: 'UserStep',
      elIcon: 'el-icon-coin'
    },
    children: [
      {
        path: 'list',
        component: () => import('@/views/user-step/List.vue'),
        name: 'UserStepList',
        meta: {
          elIcon: 'el-icon-menu',
          title: 'UserStepList',
          roles: ['admin', 'user'], // you can set roles in root nav
        }
      }],
  },
  // {
  //   path: '/user-question',
  //   component: Layout,
  //   alwaysShow: true,
  //   meta: {
  //     title: 'UserQuestion',
  //     elIcon: 'el-icon-circle-check'
  //   },
  //   children: [
  //     {
  //       path: 'list',
  //       component: () => import('@/views/user-question/List.vue'),
  //       name: 'UserQuestionList',
  //       meta: {
  //         elIcon: 'el-icon-menu',
  //         title: 'UserQuestionList',
  //         roles: ['admin', 'user'], // you can set roles in root nav
  //       }
  //     }],
  // },
  {
    path: '/user-text',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: 'UserText',
      elIcon: 'el-icon-circle-check'
    },
    children: [
      {
        path: 'list',
        component: () => import('@/views/user-text/List.vue'),
        name: 'UserTextList',
        meta: {
          elIcon: 'el-icon-menu',
          title: 'UserTextList',
          roles: ['admin', 'user'], // you can set roles in root nav
        }
      }],
  },
  {
    path: '/article',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: 'Article',
      elIcon: 'el-icon-document'
    },
    children: [

      {
        path: 'article',
        component: () => import('@/views/article/article.vue'),
        name: 'ArticleList',
        meta: { title: 'ArticleList',elIcon: 'el-icon-menu' }
      },
      {
        path: 'category',
        component: () => import('@/views/article/category.vue'),
        name: 'AritcleCategory',
        meta: { title:'AritcleCategory' ,elIcon: 'el-icon-menu' }
      }
    ],
  },
  {
    path: "/user",
    component: Layout,
    alwaysShow: true,
    meta: {
      title: "User",
      elIcon: "el-icon-user",
      roles: ["admin", "user"],
    },
    children: [
      {
        path: "fans_list",
        component: () => import("@/views/user/FansList.vue"),
        name: "FansList",
        meta: {
          title: "FansList",
          roles: ["admin", "user"],
          elIcon: "el-icon-menu",
        },
      },
    ],
  },

  // {
  //   path: "/diy-template",
  //   component: Layout,
  //   alwaysShow: true,
  //   meta: {
  //     title: "TemplateSetting",
  //     elIcon: "el-icon-template",
  //   },
  //   children: [
  //     {
  //       path: "",
  //       component: () => import("@/views/diy-template/OfficialList.vue"),
  //       name: "OfficialList",
  //       meta: {
  //         title: "OfficialList",
  //         roles: ["admin", "user"],
  //         elIcon: "el-icon-menu",
  //       },
  //     },
  //     {
  //       path: "diy-list",
  //       component: () => import("@/views/diy-template/DiyList.vue"),
  //       name: "DiyList",
  //       meta: { title: "DiyList", elIcon: "el-icon-menu" },
  //     },
  //     {
  //       path: "template-edit",
  //       component: () => import("@/views/diy-template/design/index.vue"),
  //       name: "TemplateEdit",
  //       hidden: true,
  //       meta: {
  //         title: "TemplateEdit",
  //         elIcon: "el-icon-menu",
  //         roles: ["admin"],
  //       },
  //     },
  //   ],
  // },
  {
    path: "/set",
    component: Layout,
    alwaysShow: true,
    meta: {
      title: "SystemSetting",
      elIcon: "el-icon-setting",
      roles: ["admin"],
    },
    children: [
      {
        path: "",
        component: () => import("@/views/set/set.vue"),
        name: "SystemSetting",
        meta: {
          title: "SystemSetting",
          roles: ["admin", "user"],
          elIcon: "el-icon-menu",
        },
      },
      {
        path: "user",
        component: () => import("@/views/user/User.vue"),
        name: "UserList",
        meta: {
          title: "UserList",
          roles: ["admin"],
          elIcon: "el-icon-menu",
        },
      },
      {
        path: "PicManager",
        component: () => import("@/views/set/PicManager.vue"),
        name: "PicManager",
        meta: {
          title: "PicManager",
          roles: ["admin", "user"],
          elIcon: "el-icon-menu",
        },
      },
      {
        path: "ad",
        component: () => import("@/views/ad/ad.vue"),
        name: "AdList",
        meta: { title: "AdList", elIcon: "el-icon-menu" },
      },
      {
        path: "backup",
        component: () => import("@/views/backup/backup.vue"),
        name: "DataBack",
        hidden: false,
        meta: { title: "DataBack", elIcon: "el-icon-menu", roles: ["admin"] },
      },
      // {
      //   path: 'daemon',
      //   component: () => import('@/views/system/daemon.vue'),
      //   name: 'Daemon',
      //   hidden: true,
      //   meta: { title: 'Daemon',elIcon: 'el-icon-menu',
      //     roles: ['admin'],
      //   }
      // },

      {
        path: "system-update",
        component: () => import("@/views/system/system-update.vue"),
        name: "SystemUpdate",
        hidden: false,
        meta: {
          title: "SystemUpdate",
          elIcon: "el-icon-menu",
          roles: ["admin"],
        },
      },
    ],
  },
  {
    path: '系统手册',
    component: Layout,
    children: [
      {
        path: 'https://www.kancloud.cn/sxxzwzf/yunjieli/content',
        meta: { title: '系统手册', icon: 'link' }
      }
    ]
  },
  {
    path: "/logout",
    component: Layout,
    breadcrumb: false,
    meta: {
      title: "LogOut",
      elIcon: "el-icon-switch-button",
    },
    children: [
      {
        path: "",
        component: () => import("@/views/logout/logout.vue"),
        name: "LogOut",
        meta: {
          title: "LogOut",
          elIcon: "el-icon-switch-button",
          breadcrumb: false,
          roles: ["admin", "user"],
        },
      },
    ],
  },

];

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
